@import "../../../../styles/StyleVariables.scss";
@import "../../../../styles/StyleMixins.scss";

.preview-form-heading {
    font-weight: bolder;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 50px;
}

.preview-form-container {
    border: $card-border 1px solid;
    background-color: $preview-card-color;
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 20px;    
}

.preview-image-container{
    margin: 70px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.preview-form-type-container {
    border: none;
    background-color: $card-color;
    border-radius: 8px;
    padding: 30px;
    margin-top: 40px;
}

.preview-form-organisation-title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 30px;
}

.preview-form-organisation {
    margin-top: 30px;
    margin-bottom: 40px;
    font-weight: bold;
    text-align: center;
}

.preview-form-title {
    font-weight: bolder;
    margin-bottom: 5px;
}

.preview-form-input {
    margin-bottom: 30px;
    overflow-wrap: break-word;
    border-bottom: 1px solid #eaedf4;
    padding-bottom: 30px;
}

.preview-form-input:last-child  {
    margin-bottom: 30px;
    overflow-wrap: break-word;
    border-bottom: none;
    padding-bottom: 0px;
}

.organisation-name {
    width: 100%;
    text-align: center;
    margin: 30px 0px;
}

.post-title{
    text-align: center;
    font-size: 24px;
    margin-bottom: 30px;
}

.department-container {
    margin-bottom: 21px;
    // height: 50px;
    background-color: $input-fill-color;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid $input-border-color;

    flex-wrap: wrap;
}

.department-input {
    border-radius: 4px;
    padding: 10px 10px 7px 10px;
    margin-left: 10px;
    background-color: $background-color;
    font-size: 14px;
    height: 40px;

    margin-top: 10px;
    margin-bottom: 10px;
    flex-direction: row;
    // align-items: center;
}


.view-individual-post-container {
    margin: 0px auto 200px auto;
    animation: fadeIn 1s;
    @include fadein;
    @include extrasmall {
        max-width: 90%;
        .button-container {
            max-width: 100%;
            margin: 50px auto 20px auto;
        }
    }
    @include small {
        max-width: 90%;
        .button-container {
            max-width: 100%;
            margin: 50px auto 20px auto;
        }
    }
    @include medium {
        max-width: 80%;
        .button-container {
            max-width: 100%;
            margin: 50px auto 20px auto;
        }
    }
    @include large {
        max-width: 800px;
    }
}

.button-back-edit-container {
    display: flex;
    justify-content: space-between;
    a{
      color: $primary-color;
    }
  }

.placeholder-image {
    background-color: $secondary-color;
    width: 300px;
    height: 200px;
    border-radius: 8px;
    margin: 0px auto 0px auto;
    margin-bottom: 20px;
}

.preview-form-opportunity-type {
    margin-bottom: 20px;
}

.preview-form {
    margin-top: 0px;
	animation: fadeIn 1s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.opportunity-type-icon-container {
    justify-content: center;
    align-content: center;
    text-align: center;
    img{
        margin: 20px 0;
        width: 150px;
    }
}

.top-cancel-container {
    margin-bottom: 20px;
}

.notification-text {
    color: #bfbbbb;
    justify-content: center;
}