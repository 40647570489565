//colours
$background-color: #EAEDF4;
$primary-color: #0D3058;
$secondary-color: #A1B3C9;
$button-color: #0D3058;
$logo-color: #1D3C60;
$hover-color: #A1B3C9;
$card-color: transparent;
$card-hover-color: #F4F7FC;
$card-text-hover-color: #CBD5E1;
$primary-hover-color: #9EB1C6;
$border-color: #D3DEEB;
$warning-colour: #C1544B;
$error-message-color: red;
$input-fill-color: #F8FBFF;
$input-border-color: #D3DEEB;
$input-border-active-color: #A1B3C9;
$navigation-button: #D9E2ED;
$card-border: #D3DEEB;
$preview-card-color: #F8FBFF;
$action-button-color: #506988;
$submit-color: #50AE55;
$number-color: #6281A5;
$guide-divider: #E4EBF9;

//breakpoints
$layout-breakpoint-phone-potrait: 639px;
$layout-breakpoint-phone: 640px;
$layout-breakpoint-tablet: 768px;
$layout-breakpoint-desktop: 1024px;