@import "../../../styles/StyleVariables.scss";

.footer-container {
    border-top: solid 1px $border-color;
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    // height: 10vh;
    height: 70px;
    background-color: "transparent";
    justify-content: center;
    display: flex;
    align-items: center;
}

