@mixin extrasmall {
  @media only screen and (max-width: #{$layout-breakpoint-phone-potrait}) {
      @content;
  }
}

@mixin small {
  @media only screen and (min-width: #{$layout-breakpoint-phone}) {
      @content;
  }
}

@mixin medium {
  @media only screen and (min-width: #{$layout-breakpoint-tablet}) {
      @content;
  }
}

@mixin large {
  @media only screen and (min-width: #{$layout-breakpoint-desktop}) {
      @content;
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@mixin fadein {
-webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
-moz-animation: fadein 1s; /* Firefox < 16 */
-ms-animation: fadein 1s; /* Internet Explorer */
-o-animation: fadein 1s; /* Opera < 12.1 */
animation: fadein 1s;
}