@import "../../../styles/StyleVariables.scss";
@import "../../../styles/StyleMixins.scss";

.success-navigation {
  background-color: #F8FBFF;
  border: 2px solid #0D3058;
}

.confirmation-background{
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $background-color;
    opacity: .7;
}

.confirmation-container {
    position: fixed;
    z-index: 21;
    left: 50%;
    top: 35%;
    transform: translate(-50%, -50%);
    width: 700px;
    margin: 100px auto;
    background-color: $preview-card-color;
    border: 1px solid $card-border;
    border-radius: 8px;
    padding: 50px 0px 50px 0px; 
    align-items: center;
    @include extrasmall {
        width: 90%;
        
    }
	animation: fadeIn 1s;    
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.confirmation-heading {
    text-transform: uppercase;
    text-align: center;
}

.confirmation-error-heading {
    text-transform: uppercase;
    text-align: center;
    color: $warning-colour;
}

.confirmation-subtitle {
    color: #E1867F;
    font-size: 17;
    text-align: center;
}

.confirmation-button-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    @include extrasmall {
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
    }    
}

.confirmation-button {
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
    @include extrasmall {
        margin-left: 0px;
        margin-right: 0px;
    }   
}

.confirmation-image-container{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

.edit-input-container {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-left: 100px;
    margin-right: 100px;
    justify-content: center;
    @include extrasmall {
        margin-left: 20px;
        margin-right: 20px; 
    }
}

.error-message-confirmation {
    color: $error-message-color;
    text-align: right;
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 2px;
    margin: auto;
    line-height: normal;

    @include extrasmall {
    font-size: 0.8rem;
    text-align: left;
    line-height: 10px;
    margin-right: 20px;
    margin-left: 20px;
    }
    @include small {
    font-size: 0.8rem;
    text-align: left;
    margin-left: 20px;
    }
    @include medium {
    text-align: right;
    font-size: 0.9rem;
    margin-right: 20px;
    margin-right: 100px;
    }
    @include large {
    text-align: right;
    font-size: 0.9rem;
    margin-right: 100px;
    }
}