@import "StyleVariables.scss";
@import "StyleMixins.scss";


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // border: 1px solid green;
  -webkit-text-fill-color: $primary-color;
  -webkit-box-shadow: 0 0 0px 1000px $input-fill-color inset;
}

* {
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-color;
}

h1 {
  font-size: 1.875rem;
  font-weight: bolder;
  color: $primary-color;
}

h2 {
  font-size: 1.25rem;
  font-weight: bolder;
  color: $primary-color;
  text-transform: uppercase;
}

h3 {
  font-size: 1.125rem;
  font-weight: bolder;
  color: $primary-color;
}

p {
  font-size: 1rem;
  font-weight: normal;
  color: $primary-color;
}

label {
  font-size: 1rem;
  font-weight: normal;
  color: $primary-color;
  // font-weight: bold;
}

@mixin input { 
  // width: 790px;
  width: 100%;
  // register and login input width
  height: 50px;
  border-radius: 8px;
  border: 1px solid $input-border-color;
  padding-left: 10px;
  background-color: $input-fill-color;
  font-size: 1rem;
  outline: none;
  margin: 0px 0px 0px 0px;
  display: block;
  color: $primary-color;
}

@mixin centered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
input {
  @include input;
  &::placeholder {
    color: $primary-color;
  }
  &:focus {
    @include input;
    border: 1px solid $input-border-active-color;
  }
}

@mixin textarea {
  // width: 780px;
  width: 100%;
  height: 200px;
  border-radius: 8px;
  border: 1px solid $input-border-color;
  background-color: $input-fill-color;
  margin: 0px 0px 0px 0px;
  padding: 10px;
  font-size: 1rem;
  outline: none;
  display: block;
  resize: none;
  color: $primary-color;
}

textarea {
  @include textarea;
  &:focus {
    @include textarea; 
    border: 1px solid $input-border-active-color
  }
}

select {
  // width: 800px;
  width: 100%;
  // border-radius: 8px;
  border: 2px solid $primary-color;
  background-color: $input-fill-color;
  margin: 0px 0px 30px 0px;
  padding: 10px;
  font-size: 1rem;
  outline: none;
  height: 45px;
  &:focus {
    border: 2.5px solid $secondary-color;
  }
}

// button {
//   align-items: center;
//   padding: 10px 20px 10px 20px;
//   border-radius: 10px;
//   background-color: $button-color;
//   border-color: transparent;
//   text-transform: uppercase;
//   font-weight: bolder;
//   font-size: 1rem;
//   margin: 10px 0px 10px 0px;
//   color: $background-color;
//   text-align: center;
//   outline: none;
// }

// button:hover {
//   background-color: $hover-color;
//   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
//   color: $primary-color;
//   -webkit-transition: background-color .5s ease;
//   -moz-transition: background-color .5s ease;
//   -o-transition: background-color .5s ease;
//   transition: background-color .5s ease;
//   cursor: pointer;
// }

form {
  @include extrasmall {
    max-width: 340px;
    margin: 20px auto;
    justify-content: space-between;
  .button-container {
    max-width: 100%;
  }
  }
  @include small {
    max-width: 500px;
    margin: 20px auto;
    .button-container {
      max-width: 100%;
    }
  }
  @include medium {
    max-width: 700px;
    margin: 100px auto;
    .button-container {
      max-width: 100%;
    }
  }
  @include large {
    max-width: 800px;
    margin: 100px auto 100px auto;
    .button-container {
      max-width: 100%;
      margin-top: 50px;
    }
  }

}

label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 30px;
  margin-top: 20px;
  font-size: 1rem;
}

// @mixin error-message {
.error-message {
  color: $error-message-color;
  text-align: right;
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 2px;
  margin: auto;
  line-height: normal;
  @include extrasmall {
    max-width: 340px;
    font-size: 0.9rem;
    text-align: left;
    line-height: 10px;
  }
  @include small {
    max-width: 500px;
    font-size: 0.8rem;
    text-align: left;
  }
  @include medium {
    max-width: 700px;
    text-align: right;
    font-size: 0.8rem;

  }
  @include large {
    max-width: 800px;
    text-align: right;
    font-size: 0.8rem;
  }
}

// .error-message {
//   @include error-message;
// }

  .button-container {
  display: flex;
  justify-content: space-between;
}

::placeholder {
  color: $primary-color;
}

.splash-page{
  width: 100vw;
  height: 100vh;
  background-color: $primary-color;
}

.landing-text{
  width: 100vw;
  height: 100vh;
  h1{
    @include centered;
    width: 50vw;
    text-align: center;
  }
}

// button styling
.button-icon-container {
  display: flex;
}

.icon-right-button-item {
  margin-left: 10px;
}

.icon-left-button-item {
  margin-right: 10px;
}

.button-icon-container path {
  fill: $background-color;
}

.button-icon-container:hover path {
  fill: $primary-color;
}

// next back button styling

.border-button {
  background-color: transparent;
  color: $primary-color;
  border: 2px solid $primary-color;
  display: flex;
  &:hover {
  border: 2px solid $secondary-color;
  }
}

.border-button path {
  fill: $primary-color;
}

.border-button:hover path {
  fill: $primary-color;
}



.small-logo-corner { 
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 100;
}

.small-logo-corner:hover svg { 
  // filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
}

.small-logo-corner path {
  stroke: $primary-color;
  opacity: 0.3;
}

.small-logo-corner:hover {
  path {
    opacity: 1;
    transition: ease 1s;
  }
}

.link-card{
  background-color: $button-color;
  width: 800px;
  border-radius: 10px;
  padding: 40px 40px 30px 40px;
  margin: auto auto 30px auto;
  &:hover{
    background-color: $hover-color;
    transition: background-color .2s;
  }
  h3 {
      text-align: center;
      margin-bottom: 30px
  }
  p {
      text-align: center;
      margin-bottom: 5px;
  }
  h3{
    color: $primary-color;
  }
  p{
    color: $primary-color;
  }
}

.link-divider {
  border: 1px solid $primary-color;
  width: 30px;
  margin: auto auto 30px auto;
} 



// footer styling 
* {
  box-sizing: border-box;
 }
 *:before,
 *:after {
  box-sizing: border-box;
 }
 html,
 body {
  height: 100%;
  position: relative;
 }

 .loading {
  text-align: center;
 }

.bottom-page-space {
  padding: 50px;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fade-container {
  animation: fadeIn 1s;
  @include fadein;
}