@import "../../../styles/StyleVariables.scss";
@import "../../../styles/StyleMixins.scss";

.not-found {
    position: fixed;
    background-color: $warning-colour;
    height: 100vh;
    width: 100vw;
    z-index: 1000;
    justify-content: center;
    align-items: center;
    display: flex;
    animation: fadeIn 1s;
    @include fadein;
}

.kaore-image {
    position: fixed;
}