@import "../../../styles/StyleVariables.scss";
@import "../../../styles/StyleMixins.scss";

.splash-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  // height: auto;
  background-color: $primary-color;
  position: relative;
  position: fixed;
  z-index: 1000;
}

.auckland-logo-container {
  text-align: center;
}
  
.auckland-logo-home {
  z-index: 100;
}

.text-container {
  text-align: center;
  @include extrasmall {
    margin-left: 10px;
    margin-right: 10px;
  }
  @include small {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.home-heading {
  color: $background-color;
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-top: 10px;
}

.home-text {
  color: $background-color;
  margin-bottom: 40px;
}

.big-logo-center:hover svg { 
    filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
}

.big-logo-center circle {
    transition: fill 1s ease; 
    opacity: 0%;
}
  
.big-logo-center:hover circle {
    // filter: box-shadow 0px 4px 4px rgba(0, 0, 0, 0.25);
    // opacity: 100%;
    // transition: opacity 1s ease; 
}

.big-logo-center {
  text-align: center;
}

.about-button:hover svg { 
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.about-button circle {
  transition: fill 1s ease; 
  opacity: 90%;
}

.about-button:hover circle {
  filter: box-shadow 0px 4px 4px rgba(0, 0, 0, 0.25);
  opacity: 100%;
  transition: opacity 1s ease; 
}

.about-button-container {
  z-index: 100;
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  @include extrasmall {
    right: 10px;
    top: 10px;
  }
}

.about-button-container circle {
  transition: fill 1s ease; 
  opacity: 0%;
}

.about-button-container:hover circle {
  filter: box-shadow 0px 4px 4px rgba(0, 0, 0, 0.25);
  opacity: 10%;
  transition: opacity 1s ease; 
}

.about-text {
  color: $background-color;
	animation: fadeIn 3s;
	text-align: center;
  @include extrasmall {
    padding-left: 10px;
    padding-right: 10px;
  }
  @include small {
    padding-left: 10px;
    padding-right: 10px;
  }
  @include medium {
    padding-left: 50px;
    padding-right: 50px;
  }
  @include large {
    padding-left: 300px;
    padding-right: 300px;
    line-height: 30px;
  }
}

.logo-container {
  animation: fadeIn 3s;
}

.about-button-container {
  animation: fadeIn 3s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}