@import "../../../../styles/StyleVariables.scss";
@import "../../../../styles/StyleMixins.scss";

.heading-container {
    font-weight: bolder;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-content: center;
    @include extrasmall {
        margin-bottom: 1vh;
        margin-top: 10vh;
    }
    @include small {
        margin-bottom: 2vh;
        margin-top: 10vh;
    }
    @include medium {
        margin-bottom: 3vh;
        margin-top: 10vh;
    }
    @include large {
        margin-bottom: 5vh;
        margin-top: 10vh;
    }
}

.heading-text {
    font-size: 1.5rem;
    font-weight: 900;
    letter-spacing: 0.01rem;
}

.heading-counter {
    font-size: 1.5rem;
    font-weight: 900;
    letter-spacing: 0.01rem;
    margin-right: 5px;
    color: $number-color;
}

.postings-wrapper{
  width: 100vw;
  margin-bottom: 100px;
  h1{
      margin-top: 120px;
      margin-bottom: 50px;
      width: 100%;
      text-align: center;
  }
}

.image-center{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  svg{
      width: 280px;
  }
}