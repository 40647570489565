@import "../../../styles/StyleVariables.scss";
@import "../../../styles/StyleMixins.scss";

.wrapper {
    width: 100vw;
    margin: 40px auto 30px auto;
  }
  
  .accordion-wrapper {
    & + * {
      margin-top: 0.5em;
    }
  }
  
  .accordion-item {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
  }
  
  .accordion-item.collapsed {
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
  }
  
  .accordion-title {
    border: solid 1px $input-border-active-color;
    border-radius: 4px;
    padding: 5px 16px;
    font-weight: bolder;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: $primary-color;
    margin-right: 5px;
    &:hover {
      color: #fff;
      background-color: $primary-color;
    }
  }

  .accordion-title-container {
      display: flex;
      justify-content: center;
  }

  .accordion-open-close {
    color: $primary-color;
  }
