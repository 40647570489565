@import "../../../styles/StyleVariables.scss";
@import "../../../styles/StyleMixins.scss";

.create-user-button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}

.image-jdenticon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.register-progress-bar-container {
    margin: 0px auto;
    text-align: center;
    @include extrasmall {
        max-width: 340px;
    }
    @include small {
        max-width: 500px;
    }
    @include medium {
    max-width: 700px;
    }
    @include large {
    max-width: 800px;
    }
}

.organisation-buttons {
    display: flex;
    width: 100%;
    cursor: pointer;
  }
  .organisation-button {
    width: 50%;
    border-style: solid;
    border-width: 1px;
    border-color: $input-border-color;
    background-color: $input-fill-color;
    p {
      margin: 15px;
      text-align: center;
      font-weight: bold;
    }
  }
  
  .selected {
    background-color: $action-button-color;
    p {
      margin: 15px;
      text-align: center;
      font-weight: bold;
      color: $background-color;
    }
  }
  
  .left {
    border-radius: 10px 0px 0px 10px;
  }
  
  .right {
    border-radius: 0px 10px 10px 0px;
  }
  
  .register-pop-background {
    z-index: 1000;
    height: 100vh;
    width: 100vw;
    background-color: $background-color;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    @include extrasmall {
      height: auto;
      width: auto;
      position: relative;
    }
  }
  
  .register-pop-container {
    height: 90vh;
    width: 94vw;
    background-color: $preview-card-color;
    border: $border-color 1px solid;
    display: flex;
    flex-direction: column;
    @include extrasmall {
      height: 100vh;
      width: 100vw;
      overflow: scroll;
      overflow-x: hidden;
      border-radius: 0px;
    }
    @include small {
      border-radius: 10px;
    }
    @include medium {
      border-radius: 10px;
    }
    @include large {
      border-radius: 10px;
    }
  }
  
  .guide-container {
    position: absolute;
    z-index: 199;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    width: 90vw;
    justify-content: center;
    background-color: $preview-card-color;
    border-radius: 10px;
    border: $border-color 1px solid;
    @include extrasmall {
      top: 0px;
      width: 100vw; 
      height: 100vh;
    }
  }
  
  .guide-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    height: 100%;
  }
  
  
  
  .guide-title-heading {
    font-weight: bold;
    @include extrasmall {
      font-size: 19px;
    }
    @include small {
      font-size: 21px;
    }
    @include medium {
      font-size: 22px;
    }
    @include large {
      font-size: 24px;
    }
  }
  
  .guide-heading {
    font-weight: bold;
    text-align: center;
    @include extrasmall {
      position: absolute;
      top: 150px;
      font-size: 18px;
      width: 270px;
    }
    @include small {
      font-size: 20px;
    }
    @include medium {
      font-size: 22px;
    }
    @include large {
      font-size: 24px;
    }
  }
  
  .guide-section-wrapper {
    height: 90vh;
    width: 94vw;
  }
  
  .guide-section {
    margin: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  
  .button-guide {
    width: 30px;
    height: 30px;
  }
  
  .back-guide {
    position: absolute;
    z-index: 200;
    transition: left 0.5s;
    &:hover {
      cursor: pointer;
    }
    @include extrasmall {
      left: 30px;
      bottom: 30px;
    }
    @include small {
      left: 5vw;
      top: calc(50% - 15px);
      &:hover {
        left: 4.5vw;
        }
    }
    @include medium {
      left: 5vw;
      top: calc(50% - 15px);
      &:hover {
        left: 4.5vw;
        }
    }
    @include large {
      left: 5vw;
      top: calc(50% - 15px);
    }
    &:hover {
      left: 4.5vw;
      }
  }
  
  .next-guide {
    position: absolute;
    z-index: 200;
    transition: right 0.5s;
    &:hover {
      cursor: pointer;
    }
    @include extrasmall {
      right: 30px;
      bottom: 30px;
    }
    @include small {
      right: 5vw;    
      top: calc(50% - 15px);
      &:hover {
        right: 4.5vw;
      }
    }
    @include medium {
      right: 5vw;    
      top: calc(50% - 15px);
      &:hover {
        right: 4.5vw;
      }
    }
    @include large {
      right: 5vw;
      top: calc(50% - 15px);
      &:hover {
      right: 4.5vw;
      }
    }
  }
  
  .pop-up-text {
    padding-top: 35px;
    color: $primary-color;
    text-align: center;
  }
  
  .pop-up-header {
    height: 20%;
    align-items: center;
    justify-content: center;
    display: flex;
    @include extrasmall {
      margin-top: 30px;
      svg {
        width: 250px;
      }
    }
  }
  
  .pop-close-container {
    position: absolute;
    right: 4.5vw;
    top: 7.5vh;
    cursor: pointer;
    @include extrasmall {
      top: 10px;
      right: 10px;
      svg {
        width: 51px;
      }
    }
  }
  
  .pop-close-container circle {
    opacity: 0%;
    transition: opacity 0.5s ease;
    &:hover {
      opacity: 100%;
      transition: opacity 0.5s ease;
    }
  }
  
  .pop-up-content {
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: row;
    @include extrasmall {
      flex-direction: column;
    }
  }
  
  .pop-up-web {
    border-right: solid 2px $primary-color;
    width: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
    p {
      text-align: center;
      font-weight: 600;
      line-height: 30px;
    }
    @include extrasmall {
      width: 100%;
      border-right: 0px;
      margin-bottom: 0px;
      padding: 10px 20px;
      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  
  .pop-up-divider {
    @include small {
      display: none;
    }
    @include medium {
      display: none;
    }
    @include large {
      display: none;
    }
    @include extrasmall {
      border-bottom: solid 2px $secondary-color;
      width: 75vw;
      padding: 15px;
      padding-bottom: 15px;
      margin: auto;
      margin-bottom: 20px;
      margin-top: 5px;
    }
  }
  
  .pop-up-user-option {
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
    p {
      text-align: center;
      font-weight: 600;
      line-height: 30px;
    }
    @include extrasmall {
      svg{
        width: 300px;
      }
      border-right: 0px;
      margin-bottom: 50px;
      height: 50vh;
      padding: 10px 20px;
      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  
  .pop-up-mobile {
    border-left: solid 2px $primary-color;
    width: 50%;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
    justify-content: center;
    p {
      text-align: center;
      font-weight: 600;
      line-height: 30px;
    }
    @include extrasmall {
      width: 100%;
      border-left: 0px;
      margin-bottom: 50px;
      padding: 10px 20px;
      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  
  .pop-up-footer {
    height: 20%;
    align-items: center;
    justify-content: center;
    display: flex;
    @include extrasmall {
      display: none;
    }
  }
  
  .pop-up-button {
    display: flex;
    justify-content: center;
    align-content: center;
    border: 2px $primary-color solid;
    height: 250px;
    width: 250px;
    border-radius: 125px;
    cursor: pointer;
    margin: 0px 10px 0px 10px;
    &:hover {
      background-color: $primary-color;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      p {
        color: $background-color;
      }
    }
    p {
      margin: auto;
      font-weight: bold;
    }
  }
  
  .pop-up-button-small {
    border: 2px $primary-color solid;
    padding: 8px 30px;
    border-radius: 8px;
    cursor: pointer;
    margin: 0px 10px 0px 10px;
    &:hover {
      background-color: $primary-color;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      p {
        color: $background-color;
      }
    }
    p {
      font-weight: bold;
    }
    @include extrasmall {
      position: absolute;
      bottom: 50px;
    }
  }
  
  .pop-up-button-small.left {
    @include extrasmall {
      position: relative;
      margin: 0 4px;
    }
  }
  
  .pop-up-button-small.right {
    @include extrasmall {
      position: relative;
      margin: 0 4px;
    }
  }
  
  .pop-up-button-container {
    display: flex;
    flex-direction: row;
  }
  
  .pop-up-store-button-container{
    display: flex;
    flex-direction: row;
    @include extrasmall {
      position: absolute;
      bottom: 0px;
    }
  }
  
  .guide-button-container {
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-bottom: 60px;
    @include extrasmall {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
  
  .guide-image-container {
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 270px;
    margin: 0px;
    @include extrasmall {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 270px;
      margin: 0px;
      svg {
        width: 270px;
      }
    }
    @include small {
      width: 300px;
      svg {
        width: 300px;
      }
    }
    @include medium {
      width: 350px;
      svg {
        width: 350px;
      }
    }
    @include large {
      width: 500px;
      svg {
        width: 500px;
      }
    }
  }
  
  .slide-number{
    position: absolute;
    bottom: 45px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 8px 14px;
    border: $secondary-color 2px solid;
    border-radius: 20px;
    color: $secondary-color;
    font-size: 14px;
    font-weight: bold;
    @include extrasmall {
      bottom: 15%;
    }
  }
  
  .slick-slide,
  .slick-slide * {
    outline: none !important;
  }