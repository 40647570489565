@import "../../../styles/StyleVariables.scss";
@import "../../../styles/StyleMixins.scss";

.-container {
    text-align: center;
}

.register-link {
    margin-top: 14px;
    font-size: 14px;
    font-weight: bold;
}

.checkbox-container {
    margin-top: 21px;
    display: flex;
    justify-content: center;
}

.checkbox-text{
    font-size: 14px;
}

.checkbox{
    margin-left: 10px;
}

.register-subheading{
    font-size: 14px;
}

.register-subheading-button{
    font-size: 14px;
    font-weight: 700;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    border: $action-button-color solid 1px;
    &:hover{
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        color: $background-color;
        background-color: $action-button-color;
        -webkit-transition: all .5s ease-in-out;
        -moz-transition: all .5s ease-in-out;
        -o-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;
      }
}

.login-button-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.register-button-container {
    margin-top: 70px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.buttons-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  border-color: transparent;
  justify-content: center;
  p{
    font-weight: bold;
  }
  &:focus{
    outline:none;
  }
  &:hover{
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }
}

.icon-left {
  height: 21px;
  width: 21px;
  margin-right: 10px;
  padding: 2px;
  
}

.icon-right {
  height: 21px;
  width: 21px;
  margin-left: 10px;
  padding: 2px;
  
}

.button-text {
  font-size: 17;
  font-weight: bold;
  margin-right: 10;
  margin-left: 10;
  text-align: center;
}

.action {
  background-color: $action-button-color;
  p{
    color: $background-color;
  }
  &:hover{
   background-color: darken( $action-button-color, 3% );
   -webkit-transition: all .5s ease;
   -moz-transition: all .5s ease;
   -o-transition: all .5s ease;
   transition: all .5s ease;
  } 
}
.warning {
    background-color: $warning-colour;
    p{
      color: $background-color;
    }
    &:hover{
      background-color: darken( $warning-colour, 3% );
      -webkit-transition: all .5s ease;
      -moz-transition: all .5s ease;
      -o-transition: all .5s ease;
      transition: all .5s ease;
     } 
}

.navigation {
    background-color: $navigation-button;
    border-style: solid;
    border-width: 1px;
    border-color: $secondary-color;
    p{
        color: $primary-color;
    }
    &:hover{
      background-color: darken( $navigation-button, 3% );
      -webkit-transition: all .5s ease;
      -moz-transition: all .5s ease;
      -o-transition: all .5s ease;
      transition: all .5s ease;
    }
}

.warning-navigation {
  background-color: $preview-card-color;
  border: 2px solid $warning-colour;
  p {
    color: $warning-colour;
  }
  &:hover{
  }
}

.button-loading {
  margin-left: 14px;
  svg{
    width: 21px;
    height: 21px;
  }
}



.create-log-in-form {
  margin: 0px auto;
  animation: fadeIn 1s;
    @include extrasmall {
      margin-bottom: 150px;
    }
    @include small {
      margin-bottom: 200px;
    }
    @include medium {
      max-width: 700px;
      margin-bottom: 200px;
    }
    @include large {
      max-width: 800px;
      margin-bottom: 200px;
    }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.log-in-form {
    @include medium {
      max-width: 500px;
      margin: 50px auto 300px auto;
      padding: 0px;
    }
    @include extrasmall {
      padding: 0px 5px 0px 5px;
    }
    @include small {
      padding: 0px;
    }
    @include large {
      padding: 0px;
    }
}  

.create-log-in-input {
  width: 100%;
  &:focus {
    width: 100%;
  }
}

.progress-bar-log-in-one-container {
  text-align: center;
  @include extrasmall {
    max-width: 90%;
  }
  max-width: 800px;
  margin: 100px auto 50px auto;
}

Link {
  text-decoration: none;
}

.add-details-heading {
  margin-bottom: 20px;
}


// image upload styling 
.image-container-activated {
  height: 300px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid $input-border-color;
  background-color: $input-fill-color;
  display: grid;
  grid-template-columns: 50% 50% ;
  grid-template-rows: 80% 20%;
  &:hover{
    border: 1px dashed $input-border-active-color;
    background-color: darken($input-fill-color, 1%);
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
}

.image-container-deactivated {
  height: 300px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid $input-border-color;
  background-color: $input-fill-color;
  display: grid;
  grid-template-columns: 100% ;
  grid-template-rows: 80% 20%;
}

.image-container {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 300px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid $input-border-color;
  background-color: $input-fill-color;
}

@mixin image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.upload-item {
  @include image;
  margin: 10px 0px;
  &:hover {
    background: $input-border-active-color;
    -webkit-transition: background-color .3s ease;
    -moz-transition: background-color .3s ease;
    -o-transition: background-color .3s ease;
    transition: background-color .3s ease;
  }
  @include extrasmall {

  }
}

.choosefile-item {
  @include image;
  margin: 10px 0px;
  border-left: 1px solid $input-border-color;
  cursor: pointer;

  &:hover {
    background: $secondary-color;
    border-left: 1px dotted $input-border-active-color;
    -webkit-transition: background-color .3s ease;
    -moz-transition: background-color .3s ease;
    -o-transition: background-color .3s ease;
    transition: background-color .3s ease;
  }
}

.image-subheading {
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.upload-image-text {
  padding: 10px;
  text-align: center;
  line-height: 18px;

}

.file-name-item {
  grid-column-start: span 2;
  grid-column-end: span 2;
  margin: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid $input-border-color;
  cursor: pointer;
}

.delete-file-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  margin-top: 5px;
  margin-left: 21px;
  &:hover {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: inherit;
    box-shadow: none;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
  }
  svg{
    width: 14px;
  }
}

.username-error-message {
  text-align: center;
  color: red;
  @include extrasmall {
    font-size: 0.8rem;
  }
  @include small {
    font-size: 0.8rem;
  }
  @include medium {
    font-size: 0.9rem;

  }
  @include large {
    font-size: 0.9rem;
  }
}

.log-in-heading {
  text-transform: uppercase;
  text-align: center;
}

.form-control {
  position: absolute;
  max-width: 800px;
  height: 240px;
  opacity: 0;
  cursor: pointer;  

  &:focus{
    border: none;
    opacity: 0;
  }
}

.top-back-container {
  margin-bottom: 40px;
}

.bottom-register-container {
  display: flex;
  justify-content: space-between;
  @include extrasmall {
    margin-bottom: 150px;
  }
  @include small {
    margin-bottom: 200px;
  }
  @include medium {
    margin-bottom: 200px;
  }
  @include large {
    margin-bottom: 200px;
  }
}

.top-cancel-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 14px;
}

.preview-image-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.preview-image {
  height: 220px;
}

.preview-image-existing {
  max-height: 80%;
  max-width: 80%;  
  align-self: center;
}

CustomSelect::-webkit-scrollbar-thumb {
  background: red; 
}

.-container {
  text-align: center;
}

.reset-link {
  margin-top: 5px;
  font-size: 0.9rem;
  font-weight: bold;
  &:hover {
    text-decoration: underline
  }
}

.register-link {
  margin-top: 5px;
  font-size: 14px;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
}

.checkbox-container {
  margin-top: 21px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.checkbox-text{
  font-size: 14px;
}

.checkbox{
  margin-left: 10px;
  cursor: pointer;
}

.register-subheading{
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
}

.login-button-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.reset-password-button-container {
  margin-top: 40px;
  text-align: center;
}


.register-button-container {
  margin-top: 56px;
  text-align: center;
  margin-bottom: 200px;
}

.log-in-logo {
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  path {
    stroke: $primary-color;
    opacity: 0.3;
  }
}

.log-in-logo:hover {
  path {
    opacity: 1;
    transition: all ease 1s;
  }
}


.error-message-login {
  color: $error-message-color;
  text-align: right;
  position: absolute;
  width: 500px;
  margin-top: 2px;
  font-size: 0.9rem;
  @include extrasmall {
    max-width: 340px;
    font-size: 0.8rem;
    text-align: left;
  }
  @include small {
    max-width: 500px;
    font-size: 0.8rem;
    text-align: left;
  }
  @include medium {
    max-width: 700px;
    text-align: right;
    font-size: 0.8rem;

  }
  @include large {
    max-width: 800px;
    text-align: right;
    font-size: 0.8rem;
  }
}

.reset-message {
  color: $primary-color;
  text-align: center;
  position: absolute;
  width: 500px;
  margin-top: 2px;
  font-size: 0.9rem;
  @include extrasmall {
    max-width: 340px;
    font-size: 0.8rem;
  }
  @include small {
    max-width: 500px;
    font-size: 0.8rem;
  }
  @include medium {
    max-width: 700px;
    font-size: 0.8rem;

  }
  @include large {
    max-width: 800px;
    font-size: 0.8rem;
  }
}

.error-message-login-1 {
  color: $error-message-color;
  text-align: center;
  position: absolute;
  width: 500px;
  margin-top: 2px;
  font-size: 0.8rem;
  @include extrasmall {
    max-width: 340px;
    font-size: 0.8rem;
    text-align: center;
  }
  @include small {
    max-width: 500px;
    font-size: 0.8rem;
    text-align: center;
  }
  @include medium {
    max-width: 700px;
    text-align: center;
    font-size: 0.8rem;

  }
  @include large {
    max-width: 800px;
    text-align: center;
    font-size: 0.8rem;
  }
}

