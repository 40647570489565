@import "../../../styles/StyleVariables.scss";
@import "../../../styles/StyleMixins.scss";

.guide-card-heading {
    text-align: center;
    margin: 100px 0px 100px 0px;
    font-size: 1rem;
    font-weight: 900;
}

.getting-started-container {
    margin-bottom: 200px;
}

.getting-started-item-container {
    margin: auto;
    display: flex;
    flex-direction: column;
    @include extrasmall {
        max-width: 80%;
    }
    @include small {
        max-width: 80%;
    }
    @include medium {
        max-width: 80%;
    }
    @include large {
        max-width: 800px;
    }
}

.getting-started-list {
    color: $primary-color;
    margin-bottom: 10px;
    line-height: 150%;
    text-align: justify;
}

.getting-started-image {
    margin-top: 100px;
    margin-bottom: 0px;
    -webkit-box-shadow: 3px 3px 20px 3px #d6d6d6;  
    -moz-box-shadow:    3px 3px 20px 3px #d6d6d6;  
    box-shadow:         3px 3px 20px 3px #d6d6d6;  
    margin-bottom: 55px;
    img {
        width: 100%;
        height: auto;
    } 
}

.getting-started-image-top {
    margin-top: 10px;
    -webkit-box-shadow: 3px 3px 20px 3px #d6d6d6;  
    -moz-box-shadow:    3px 3px 20px 3px #d6d6d6;  
    box-shadow:         3px 3px 20px 3px #d6d6d6;  
    margin-bottom: 55px;
    img {
        width: 100%;
        height: auto;
    } 
}

.getting-started-heading {
    margin: 20px 0px 20px 0px; 
    font-weight: 700;
}

.faq-search-container {
    margin: auto auto 100px auto;
    
    @include extrasmall {
        max-width: 90%;
    }
    @include small {
        max-width: 90%;
    }
    @include medium {
        max-width: 80%;
    }
    @include large {
        max-width: 800px;
    }
}

.faq-list-heading {
    font-size: 1rem;
    text-align: center;
    color: $primary-color;
    font-weight: bold;
}

.emptybox-center {
    text-align: center;
    svg {
        @include large {
            max-width: 200px;
        }
        @include extrasmall {
            max-width: 50%;
        }
    }
}

.guide-getting-started-button {
    border: 2px $primary-color solid;
    padding: 8px 30px;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background-color: $primary-color;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      p {
        color: $background-color;
      }
    }
    p {
      font-weight: bold;
    }
    @include extrasmall {
      position: absolute;
      bottom: 50px;
    }
  }

  .guide-getting-started-container {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      flex-direction: column;
      height: 100%;
  }

  