.loading-icon{
  display: flex;
  flex: 1;
  position: fixed;  
  z-index: 2000;
  background: rgba(255,255,255, .95);
  height: 100%;
  width: 100%;
}

.image-loading-icon{
  svg{
     width: 300px; 
  }
}