@import "./StyleVariables.scss";
@import "./StyleMixins.scss";

.screen-container {
    min-height: 100vh;
    overflow: hidden;
    display: block;
    position: relative;
   }

/* width */
::-webkit-scrollbar {
    width: 10px;
    @include extrasmall {
      width: 0px;
    }
  }

  
  /* Track */
  ::-webkit-scrollbar-track {
    background: lighten($card-text-hover-color, 5); 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $card-text-hover-color; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $primary-color; 
  }
