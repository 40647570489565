@import "../../../styles/StyleVariables.scss";
@import "../../../styles/StyleMixins.scss";

.create-log-in-form {
  margin: 0px auto;
	animation: fadeIn 1s;
    @include extrasmall {
      margin-bottom: 150px;
    }
    @include small {
      margin-bottom: 200px;
    }
    @include medium {
      max-width: 700px;
      margin-bottom: 200px;
    }
    @include large {
      max-width: 800px;
      margin-bottom: 200px;
    }
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.log-in-form {
    @include medium {
      max-width: 500px;
      margin: 50px auto 300px auto;
      padding: 0px;
    }
    @include extrasmall {
      padding: 0px 5px 0px 5px;
    }
    @include small {
      padding: 0px;
    }
    @include large {
      padding: 0px;
    }
}  

.create-log-in-input {
  width: 100%;
  &:focus {
    width: 100%;
  }
}

.progress-bar-log-in-one-container {
  text-align: center;
  @include extrasmall {
    max-width: 90%;
  }
  max-width: 800px;
  margin: 100px auto 50px auto;
}

Link {
  text-decoration: none;
}

.add-details-heading {
  margin-bottom: 20px;
}


// image upload styling 
.image-container-activated {
  height: 300px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid $input-border-color;
  background-color: $input-fill-color;
  display: grid;
  grid-template-columns: 50% 50% ;
  grid-template-rows: 80% 20%;
  &:hover{
    border: 1px dashed $input-border-active-color;
    background-color: darken($input-fill-color, 1%);
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
}

.image-container-deactivated {
  height: 300px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid $input-border-color;
  background-color: $input-fill-color;
  display: grid;
  grid-template-columns: 100% ;
  grid-template-rows: 80% 20%;
}

.image-container {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 300px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid $input-border-color;
  background-color: $input-fill-color;
}

@mixin image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.upload-item {
  @include image;
  margin: 10px 0px;
  &:hover {
    background: $input-border-active-color;
    -webkit-transition: background-color .3s ease;
    -moz-transition: background-color .3s ease;
    -o-transition: background-color .3s ease;
    transition: background-color .3s ease;
  }
  @include extrasmall {

  }
}

.choosefile-item {
  @include image;
  margin: 10px 0px;
  border-left: 1px solid $input-border-color;
  cursor: pointer;

  &:hover {
    background: $secondary-color;
    border-left: 1px dotted $input-border-active-color;
    -webkit-transition: background-color .3s ease;
    -moz-transition: background-color .3s ease;
    -o-transition: background-color .3s ease;
    transition: background-color .3s ease;
  }
}

.image-subheading {
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.upload-image-text {
  padding: 10px;
  text-align: center;
  line-height: 18px;

}

.file-name-item {
  grid-column-start: span 2;
  grid-column-end: span 2;
  margin: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid $input-border-color;
  cursor: pointer;
}

.delete-file-button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	cursor: pointer;
  outline: inherit;
  margin-top: 5px;
  margin-left: 21px;
  &:hover {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: inherit;
    box-shadow: none;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
  }
  svg{
    width: 14px;
  }
}

.username-error-message {
  text-align: center;
  color: red;
  @include extrasmall {
    font-size: 0.8rem;
  }
  @include small {
    font-size: 0.8rem;
  }
  @include medium {
    font-size: 0.9rem;

  }
  @include large {
    font-size: 0.9rem;
  }
}

.log-in-heading {
  text-transform: uppercase;
  text-align: center;
}

.form-control {
  position: absolute;
  max-width: 800px;
  height: 240px;
  opacity: 0;
  cursor: pointer;  

  &:focus{
    border: none;
    opacity: 0;
  }
}

.top-back-container {
  margin-bottom: 40px;
}

.bottom-register-container {
  display: flex;
  justify-content: space-between;
  @include extrasmall {
    margin-bottom: 150px;
  }
  @include small {
    margin-bottom: 200px;
  }
  @include medium {
    margin-bottom: 200px;
  }
  @include large {
    margin-bottom: 200px;
  }
}

.top-cancel-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 14px;
}

.preview-image-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.preview-image {
  height: 220px;
}

.preview-image-existing {
  max-height: 80%;
  max-width: 80%;  
  align-self: center;
}

CustomSelect::-webkit-scrollbar-thumb {
  background: red; 
}

.-container {
  text-align: center;
}

.reset-link {
  margin-top: 5px;
  font-size: 0.9rem;
  font-weight: bold;
  &:hover {
    text-decoration: underline
  }
}

.register-link {
  margin-top: 5px;
  font-size: 14px;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
}

.checkbox-container {
  margin-top: 21px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.checkbox-text{
  font-size: 14px;
}

.checkbox{
  margin-left: 10px;
  cursor: pointer;
}

.register-subheading{
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
}

.login-button-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.reset-password-button-container {
  margin-top: 40px;
  text-align: center;
}


.register-button-container {
  margin-top: 56px;
  text-align: center;
  margin-bottom: 200px;
}

.log-in-logo {
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  path {
    stroke: $primary-color;
    opacity: 0.3;
  }
}

.log-in-logo:hover {
  path {
    opacity: 1;
    transition: all ease 1s;
  }
}


.error-message-login {
  color: $error-message-color;
  text-align: right;
  position: absolute;
  width: 500px;
  margin-top: 2px;
  font-size: 0.9rem;
  @include extrasmall {
    max-width: 340px;
    font-size: 0.8rem;
    text-align: left;
  }
  @include small {
    max-width: 500px;
    font-size: 0.8rem;
    text-align: left;
  }
  @include medium {
    max-width: 700px;
    text-align: right;
    font-size: 0.8rem;

  }
  @include large {
    max-width: 800px;
    text-align: right;
    font-size: 0.8rem;
  }
}

.reset-message {
  color: $primary-color;
  text-align: center;
  position: absolute;
  width: 500px;
  margin-top: 2px;
  font-size: 0.9rem;
  @include extrasmall {
    max-width: 340px;
    font-size: 0.8rem;
  }
  @include small {
    max-width: 500px;
    font-size: 0.8rem;
  }
  @include medium {
    max-width: 700px;
    font-size: 0.8rem;

  }
  @include large {
    max-width: 800px;
    font-size: 0.8rem;
  }
}

.error-message-login-1 {
  color: $error-message-color;
  text-align: center;
  position: absolute;
  width: 500px;
  margin-top: 2px;
  font-size: 0.8rem;
  @include extrasmall {
    max-width: 340px;
    font-size: 0.8rem;
    text-align: center;
  }
  @include small {
    max-width: 500px;
    font-size: 0.8rem;
    text-align: center;
  }
  @include medium {
    max-width: 700px;
    text-align: center;
    font-size: 0.8rem;

  }
  @include large {
    max-width: 800px;
    text-align: center;
    font-size: 0.8rem;
  }
}

.organisation-buttons {
  display: flex;
  width: 100%;
  cursor: pointer;
}
.organisation-button {
  width: 50%;
  border-style: solid;
  border-width: 1px;
  border-color: $input-border-color;
  background-color: $input-fill-color;
  p {
    margin: 15px;
    text-align: center;
    font-weight: bold;
  }
}

.selected {
  background-color: $action-button-color;
  p {
    margin: 15px;
    text-align: center;
    font-weight: bold;
    color: $background-color;
  }
}

.left {
  border-radius: 10px 0px 0px 10px;
}

.right {
  border-radius: 0px 10px 10px 0px;
}

.register-pop-background {
  z-index: 1000;
  height: 100vh;
  width: 100vw;
  background-color: $background-color;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  @include extrasmall {
    height: auto;
    width: auto;
    position: relative;
  }
}

.register-pop-container {
  height: 90vh;
  width: 94vw;
  background-color: $preview-card-color;
  border: $border-color 1px solid;
  display: flex;
  flex-direction: column;
  @include extrasmall {
    height: 100vh;
    width: 100vw;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 0px;
  }
  @include small {
    border-radius: 10px;
  }
  @include medium {
    border-radius: 10px;
  }
  @include large {
    border-radius: 10px;
  }
}

.guide-container {
  position: absolute;
  z-index: 199;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  width: 90vw;
  justify-content: center;
  background-color: $preview-card-color;
  border-radius: 10px;
  border: $border-color 1px solid;
  @include extrasmall {
    top: 0px;
    width: 100vw; 
    height: 100vh;
  }
}

.guide-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  height: 100%;
}



.guide-title-heading {
  font-weight: bold;
  @include extrasmall {
    font-size: 19px;
  }
  @include small {
    font-size: 21px;
  }
  @include medium {
    font-size: 22px;
  }
  @include large {
    font-size: 24px;
  }
}

.guide-heading {
  font-weight: bold;
  text-align: center;
  @include extrasmall {
    position: absolute;
    top: 150px;
    font-size: 18px;
    width: 270px;
  }
  @include small {
    font-size: 20px;
  }
  @include medium {
    font-size: 22px;
  }
  @include large {
    font-size: 24px;
  }
}

.guide-section-wrapper {
  height: 90vh;
  width: 94vw;
}

.guide-section {
  margin: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.button-guide {
  width: 30px;
  height: 30px;
}

.back-guide {
  position: absolute;
  z-index: 200;
  transition: left 0.5s;
  &:hover {
    cursor: pointer;
  }
  @include extrasmall {
    left: 30px;
    bottom: 30px;
  }
  @include small {
    left: 5vw;
    top: calc(50% - 15px);
    &:hover {
      left: 4.5vw;
      }
  }
  @include medium {
    left: 5vw;
    top: calc(50% - 15px);
    &:hover {
      left: 4.5vw;
      }
  }
  @include large {
    left: 5vw;
    top: calc(50% - 15px);
  }
  &:hover {
    left: 4.5vw;
    }
}

.next-guide {
  position: absolute;
  z-index: 200;
  transition: right 0.5s;
  &:hover {
    cursor: pointer;
  }
  @include extrasmall {
    right: 30px;
    bottom: 30px;
  }
  @include small {
    right: 5vw;    
    top: calc(50% - 15px);
    &:hover {
      right: 4.5vw;
    }
  }
  @include medium {
    right: 5vw;    
    top: calc(50% - 15px);
    &:hover {
      right: 4.5vw;
    }
  }
  @include large {
    right: 5vw;
    top: calc(50% - 15px);
    &:hover {
    right: 4.5vw;
    }
  }
}

.pop-up-text {
  padding-top: 35px;
  color: $primary-color;
  text-align: center;
}

.pop-up-header {
  height: 20%;
  align-items: center;
  justify-content: center;
  display: flex;
  @include extrasmall {
    margin-top: 30px;
    svg {
      width: 250px;
    }
  }
}

.pop-close-container {
  position: absolute;
  right: 4.5vw;
  top: 7.5vh;
  cursor: pointer;
  @include extrasmall {
    top: 10px;
    right: 10px;
    svg {
      width: 51px;
    }
  }
}

.pop-close-container circle {
  opacity: 0%;
  transition: opacity 0.5s ease;
  &:hover {
    opacity: 100%;
    transition: opacity 0.5s ease;
  }
}

.pop-up-content {
  height: 60%;
  width: 100%;
  display: flex;
  flex-direction: row;
  @include extrasmall {
    flex-direction: column;
  }
}

.pop-up-web {
  border-right: solid 2px $primary-color;
  width: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  p {
    text-align: center;
    font-weight: 600;
    line-height: 30px;
  }
  @include extrasmall {
    width: 100%;
    border-right: 0px;
    margin-bottom: 0px;
    padding: 10px 20px;
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.pop-up-divider {
  @include small {
    display: none;
  }
  @include medium {
    display: none;
  }
  @include large {
    display: none;
  }
  @include extrasmall {
    border-bottom: solid 2px $secondary-color;
    width: 75vw;
    padding: 15px;
    padding-bottom: 15px;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 5px;
  }
}

.pop-up-user-option {
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  p {
    text-align: center;
    font-weight: 600;
    line-height: 30px;
  }
  @include extrasmall {
    svg{
      width: 300px;
    }
    border-right: 0px;
    margin-bottom: 50px;
    height: 50vh;
    padding: 10px 20px;
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.pop-up-mobile {
  border-left: solid 2px $primary-color;
  width: 50%;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  justify-content: center;
  p {
    text-align: center;
    font-weight: 600;
    line-height: 30px;
  }
  @include extrasmall {
    width: 100%;
    border-left: 0px;
    margin-bottom: 50px;
    padding: 10px 20px;
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.pop-up-footer {
  height: 20%;
  align-items: center;
  justify-content: center;
  display: flex;
  @include extrasmall {
    display: none;
  }
}

.pop-up-button {
  display: flex;
  justify-content: center;
  align-content: center;
  border: 2px $primary-color solid;
  height: 250px;
  width: 250px;
  border-radius: 125px;
  cursor: pointer;
  margin: 0px 10px 0px 10px;
  &:hover {
    background-color: $primary-color;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    p {
      color: $background-color;
    }
  }
  p {
    margin: auto;
    font-weight: bold;
  }
}

.pop-up-button-small {
  border: 2px $primary-color solid;
  padding: 8px 30px;
  border-radius: 8px;
  cursor: pointer;
  margin: 0px 10px 0px 10px;
  &:hover {
    background-color: $primary-color;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    p {
      color: $background-color;
    }
  }
  p {
    font-weight: bold;
  }
  @include extrasmall {
    position: absolute;
    bottom: 50px;
  }
}

.pop-up-button-small.left {
  @include extrasmall {
    position: relative;
    margin: 0 4px;
  }
}

.pop-up-button-small.right {
  @include extrasmall {
    position: relative;
    margin: 0 4px;
  }
}

.pop-up-button-container {
  display: flex;
  flex-direction: row;
}

.pop-up-store-button-container{
  display: flex;
  flex-direction: row;
  @include extrasmall {
    position: absolute;
    bottom: 0px;
  }
}

.guide-button-container {
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-bottom: 60px;
  @include extrasmall {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.guide-image-container {
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 270px;
  margin: 0px;
  @include extrasmall {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 270px;
    margin: 0px;
    svg {
      width: 270px;
    }
  }
  @include small {
    width: 300px;
    svg {
      width: 300px;
    }
  }
  @include medium {
    width: 350px;
    svg {
      width: 350px;
    }
  }
  @include large {
    width: 500px;
    svg {
      width: 500px;
    }
  }
}

.slide-number{
  position: absolute;
  bottom: 45px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 8px 14px;
  border: $secondary-color 2px solid;
  border-radius: 20px;
  color: $secondary-color;
  font-size: 14px;
  font-weight: bold;
  @include extrasmall {
    bottom: 15%;
  }
}

.slick-slide,
.slick-slide * {
  outline: none !important;
}
