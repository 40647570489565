@import "../../../styles/StyleVariables.scss";
@import "../../../styles/StyleMixins.scss";

.button-container {
    display: flex;
    margin: 50px auto 20px auto;
    @include extrasmall {
        max-width: 90%;
    }
    @include small {
        max-width: 90%;
    }
    @include medium {
        max-width: 80%;
    }
    @include large {
        max-width: 800px;        
    }
}

.organisation-profile-container {
    margin: 10px auto 10px auto;
    border-radius: 10px;
    padding: 20px;
    background-color: $preview-card-color;
    border: $card-border 1px solid;
    @include extrasmall {
        max-width: 90%;
    }
    @include small {
        max-width: 90%;
    }
    @include medium {
        max-width: 80%;
    }
    @include large {
        max-width: 800px;        
    }
}

.organisation-profile-image-placeholder{
    position: relative;
    display: flex;
    justify-content: center;
    padding: 40px 0 65px 0;
    border-bottom: solid 1.5px $input-border-color;
}

.profile-image {
    align-self: center;
    border-radius: 3px;
    width: 300px;
    height: auto;
}

.organisation-profile-heading {
    margin-bottom: 40px;
    text-align: center;
}

.organisation-profile-contact {
    margin-bottom: 10px;
}

.organisation-profile-paragraph {
    margin-bottom: 20px;
}

.back-edit-button-container {
    max-width: 840px;
    margin: 10px auto 10px auto;
}

.organisation-profile-button {
    margin-right: 20px;
}

.organisation-profile-top-margin {
    margin-top: 100px;
    @include extrasmall {
        margin-bottom: 150px;
    }
    @include small {
        margin-bottom: 150px;
    }
    @include medium {
        margin-bottom: 150px;
    }
    @include large {
        margin-bottom: 200;
    }
}

.profile-label {
    font-weight: bold;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.profile-text {
    margin-bottom: 25px;
}

.organisation-image {
    width: 100%;
}