@import "../../../styles/StyleVariables.scss";
@import "../../../styles/StyleMixins.scss";

.change-password-text {
  margin-bottom: 20px;
  text-align: center;
}

.change-password-list {
  text-align: center;
}

.change-password-text-container {
  margin-top: 50px;
}

.success-message-login {
  color: #0D3058;
  text-align: center;
  width: 500px;
  margin-top: 2px;
  font-size: 1.2rem;  
}