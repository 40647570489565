@import "../../../styles/StyleVariables.scss";
@import "../../../styles/StyleMixins.scss";


.about-container {
    margin: 0 auto 20vh auto;
    animation: fadeIn 1s;
    @include fadein;
    @include extrasmall {
        max-width: 90vw;
    }
    @include medium {
        max-width: 70vw;
    }
    @include large {
        max-width: 800px;

    }
}

.about-page-text {
    margin-bottom: 20px;
    text-align: justify;
}

.about-link {
    font-weight: bold;
    color: $primary-color;
    &:hover {
        border-bottom: 2px solid $primary-color;
        padding-bottom: 1px;
        cursor: pointer;
    }
}

.about-logo {
    text-align: center;
    margin-bottom: 3vh;
}