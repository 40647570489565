@import "../../../styles/StyleVariables.scss";
@import "../../../styles/StyleMixins.scss";

.checkbox-wrapper {
  width: 70px;
  height: 70px;
  position: fixed;
  z-index: 300;
  top: 0px;
  left: 20px;
}

.checkbox-wrapper {
  input {
    width: 70px;
    height: 70px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 250;
    cursor: pointer;
    opacity: 0;
    -webkit-touch-callout: none;
  }
  span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: absolute;
    z-index: 205;
    right: 30px;
    top: 30px;
    background: $primary-color;
    border-radius: 3px;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
    opacity: 0.4;
  }
}
  
.checkbox-wrapper:hover span {
  opacity: 0.9;
}
  
.checkbox-wrapper span:first-child {
  transform-origin: 0% 0%;
}
  
.checkbox-wrapper span:nth-child(2) {
  transform: translate(0px, 10px);
}
  
.checkbox-wrapper span:nth-child(3) {
  transform: translate(0px, 20px);
}
  
.checkbox-wrapper input:checked ~ span {
  position: absolute;
  opacity: 1;
  transform: rotate(45deg) translate(0, 0);
  background: $background-color;
}
  
.checkbox-wrapper input:checked ~ span:nth-last-child(3) {
  position: absolute;
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}
  
.checkbox-wrapper input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(-14px, 11px);
}

@mixin menu {
  position: fixed;
  z-index: 101;
  display: block;
  top: 0;
  left: 0;
  height: 100vh;
  margin: 0;
  padding-top: 25vh;
  padding-left: 10vw;
  transform-origin: 0% 0%;
  @include extrasmall {
    width: 90vw;
  }
  @include small {
    width: 80vw;
  }
  @include medium {
  width: 50vw;
  }
  @include large {
    width: 35vw;
    }
}

@mixin mask {
  position: fixed;
  z-index: 103;
  display: block;
  top: 0;
  right: 0;
  height: 100vh;
  margin: 0;
  padding-top: 25vh;
  padding-left: 10vw;
  transform-origin: 0% 0%;
  @include extrasmall {
    width: 10vw;
  }
  @include small {
    width: 20vw;
  }
  @include medium {
  width: 50vw;
  }
  @include large {
    width: 65vw;
    }
}

#menu-unchecked {
  @include menu;
  background: $primary-color;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu-checked {
  @include menu;
  background: $primary-color;
  transform-origin: 0% 0%;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.65);
  -moz-box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.65);
  box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.65);
}

.screen-mask{
  @include mask;
  background-color: transparent;
}

.screen-mask-none{
  display: none;
}

.nav-item {
  margin-bottom: 5vh;
}

.normal {
  font-size: 1.25rem;
  font-weight: bolder;
  color: $background-color;
  text-decoration: none;
  opacity: .6;
  transition: 0.5s;
  &:hover {
    opacity: 1;
  }
}

.active {
  opacity: 1;
  margin-bottom: 5vh;
  font-weight: 900;
}

a {
  text-decoration: none;
}

.postings-text {
  font-size: 1.25rem;
  font-weight: bolder;
  color: $background-color;
  text-decoration: none;
  opacity: .6;
  transition: 0.5s;
  display: flex;
  &:hover {
    opacity: 1;
  }
  cursor: pointer;
}

.postings-asset {
  font-size: 1.25rem;
  font-weight: bolder;
  color: $background-color;
  text-decoration: none;
  opacity: .6;
  transition: 0.5s;
  display: flex;
  &:hover {
    opacity: 1;
  }
  cursor: pointer;
  margin-left: 10px;

}

.postings-container {
  margin-bottom: 5vh;
}

.postings-list {
  color: $background-color;
}

.postings-heading {
  display: flex;
}

.pending-normal {
  font-size: 1rem;
  font-weight: bolder;
  color: $background-color;
  text-decoration: none;
  opacity: .6;
  transition: 0.5s;
  &:hover {
    opacity: 1;
  }
}

.pending-active {
  opacity: 1;
  margin-bottom: 1vh;
  font-weight: 900;
}


.pending-item {
  margin-bottom: 2vh;
  margin-top: 2vh;
}


.postings-list-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height .3s ease-in-out;
  margin-left: 40px;
}

.item-container {
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.nav-item-logout {
  font-size: 14px;
  color: $background-color;
  font-weight: bold;
  // margin-top: 30vh;
  transition: 0.5s;
  opacity: .6;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  justify-self: end;
}