@import "../../../styles/StyleVariables.scss";
@import "../../../styles/StyleMixins.scss";

.posting-card-container {
    background-color: $card-color;
    height: auto;
    // width: 800px;
    margin: auto auto 30px auto;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    border: $card-border solid 1px;

    &:hover {
        background-color: $card-hover-color;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        -webkit-transition: background-color .5s ease;
        -moz-transition: background-color .5s ease;
        -o-transition: background-color .5s ease;
        transition: background-color .5s ease;
        .review-divider {
            border: 1px solid $card-color;            
        }
        cursor: pointer;
    }
    @include extrasmall {
        max-width: 90%;
    }
    @include small {
        max-width: 90%;
    }
    @include medium {
        max-width: 80%;
    }
    @include large {
        max-width: 800px;
    }
}

.posting-card-title {
    margin-bottom: 20px;
    text-transform: capitalize;
}

.posting-card-type {
    margin-bottom: 20px;
    font-size: 14px;
    @include extrasmall {
        margin-bottom: 20px;
    }
}

.posting-card-date {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 65px;
    margin-right: 20px;
    @include extrasmall {
        position: relative; 
        margin-top: 0px;
        margin-right: 0px;
        margin-bottom: 20px;
    }
    @include extrasmall {
        font-size: 14px;
    }
    @include small {
        font-size: 14px;
    }
}

.posting-card-description {
    @include extrasmall {
        font-size: 14px;
    }
    @include small {
        font-size: 14px;
    }
}

.posting-card-published {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 25px;
    margin-right: 20px;
    @include extrasmall {
        position: relative;
        margin-top: 0px;
        margin-right: 0px; 
        margin-bottom: 20px;
    }
    @include extrasmall {
        font-size: 14px;
    }
    @include small {
        font-size: 14px;
    }
}

// .review-card-container {
//     width: 800px;
//     height: 150px;
//     border-radius: 10px;
//     background-color: $card-color;
//     margin: auto auto 30px auto;
//     padding: 50px;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     h3 {
//         color: $primary-color;
//     }
//     p {
//         color: $primary-color;
//     }
//     &:hover {
//         background-color: $card-hover-color;
//         box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
//         -webkit-transition: background-color .5s ease;
//         -moz-transition: background-color .5s ease;
//         -o-transition: background-color .5s ease;
//         transition: background-color .5s ease;
//         h3 {
//             color: $card-color;
//         }
//         p {
//             color: $card-color;
//         }
//         .review-divider {
//             border: 1px solid $card-color;            
//         }
//         }
//     cursor: pointer;
// }