@import "../../../styles/StyleVariables.scss";
@import "../../../styles/StyleMixins.scss";


.contact-container {
    margin: 0 auto 20vh auto;
    animation: fadeIn 1s;
    @include fadein;
    @include extrasmall {
        max-width: 90vw;
    }
    @include medium {
        max-width: 70vw;
    }
    @include large {
        max-width: 800px;

    }
}

.contact-card-container {
    border: 1px solid $card-border;
    background-color: $preview-card-color;
    border-radius: 8px;
    h3 {
        text-align: center;
        margin-bottom: 30px
    }
    p {
        text-align: center;
        margin-bottom: 5px;
    }
    @include extrasmall {
        padding: 20px 20px 15px 20px;
        margin: 20px 0px 20px 0px; 
    }
    @include medium {
        padding: 40px 40px 30px 40px;
        margin: 20px 0px 20px 0px;
        max-width: 800px;
    }
    @include large {
        max-width: 800px;
    }
}

.contact-divider {
    border: 1px solid $primary-color;
    width: 30px;
    margin: auto auto 30px auto;
}   

.email-style {
    text-decoration: none;
    color: $primary-color;
    &:hover {
        font-weight: bold;
        transition: font-weight; 
    }
}

.help-image-container {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 30px;
}

.help-image {
    border-radius: 50%;
    filter: grayscale(100%);
    opacity: 1;
    height: 250px;
    width: 250px;
  }