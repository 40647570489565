@import "../../../styles/StyleVariables.scss";
@import "../../../styles/StyleMixins.scss";

.organisations-list-container {
    margin: auto;
    animation: fadeIn 1s;
    @include fadein;
    @include extrasmall {
        max-width: 90%;
    }
    @include small {
        max-width: 80%;
    }
    @include medium {
        max-width: 80%;
    }
    @include large {
        max-width: 800px;
    }
}

.organisation-card-container {
    border-radius: 10px;
    border: $card-border solid 1px;
    background-color: $card-color;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
        background-color: $card-hover-color;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        -webkit-transition: background-color .5s ease;
        -moz-transition: background-color .5s ease;
        -o-transition: background-color .5s ease;
        transition: background-color .5s ease;
        }
    cursor: pointer;

    @include extrasmall {
        height: 300px;
        margin-bottom: 20px;
    }
    @include small {
        height: 300px;
        margin-bottom: 20px;
    }
    @include small {
        height: 300px;
        margin-bottom: 20px;
    }
    @include large {
        height: 300px;
        margin-bottom: 20px;
    }
}

.organisation-card-name {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
}

.organisation-list-container {
    max-width: 800px;
    margin: 100px auto 100px auto;
}

.organisation-heading {
    margin-bottom: 50px;
    text-transform: uppercase;
}

.organisation-card-image {    
    width: 200px;
}

.overflow-center {
    position: absolute;    
}

.image-overflow-wrapper {
    overflow: hidden;
    width: 200px;
    height: 150px;
    position: relative;
}