@import "../../../styles/StyleVariables.scss";
@import "../../../styles/StyleMixins.scss";

.buttons-style {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    border-color: transparent;
    justify-content: center;
    p{
      font-weight: bold;
    }
    &:focus{
      outline:none;
    }
    &:hover{
      cursor: pointer;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
  }

.icon-left {
    height: 21px;
    width: 21px;
    margin-right: 10px;
    padding: 2px;
    
  }

  .icon-right {
    height: 21px;
    width: 21px;
    margin-left: 10px;
    padding: 2px;
    
  }

.button-text {
    font-size: 17;
    font-weight: bold;
    margin-right: 10;
    margin-left: 10;
    text-align: center;
  }

.action-green {
    background-color: #50AE55;
    p{
      color: $background-color;
    }
    &:hover{
     background-color: darken( #50AE55, 3% );
     -webkit-transition: all .5s ease;
     -moz-transition: all .5s ease;
     -o-transition: all .5s ease;
     transition: all .5s ease;
    } 
}


.action {
    background-color: $action-button-color;
    p{
      color: $background-color;
    }
    &:hover{
     background-color: darken( $action-button-color, 3% );
     -webkit-transition: all .5s ease;
     -moz-transition: all .5s ease;
     -o-transition: all .5s ease;
     transition: all .5s ease;
    } 
}
  .warning {
      background-color: $warning-colour;
      p{
        color: $background-color;
      }
      &:hover{
        background-color: darken( $warning-colour, 3% );
        -webkit-transition: all .5s ease;
        -moz-transition: all .5s ease;
        -o-transition: all .5s ease;
        transition: all .5s ease;
       } 
  }

  .navigation {
      background-color: $navigation-button;
      border-style: solid;
      border-width: 1px;
      border-color: $secondary-color;
      p{
          color: $primary-color;
      }
      &:hover{
        background-color: darken( $navigation-button, 3% );
        -webkit-transition: all .5s ease;
        -moz-transition: all .5s ease;
        -o-transition: all .5s ease;
        transition: all .5s ease;
      }
  }

  .warning-navigation {
    background-color: $preview-card-color;
    border: 2px solid $warning-colour;
    p {
      color: $warning-colour;
    }
    &:hover{
    }
  }

  .button-loading {
    margin-left: 14px;
    svg{
      width: 21px;
      height: 21px;
    }
  }