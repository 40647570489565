@import "../../../../styles/StyleVariables.scss";
@import "../../../../styles/StyleMixins.scss";

input.disabledField {
  background: gray;
  color: white;
}

.create-heading {
    margin-top: 50px;
    margin-bottom: 30px;
    text-transform: uppercase;
    text-align: left;
}

.create-paragraph {
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
}

.progress-bar-one {
  width: 10vw;
}

.progress-bar-container {
  text-align: center;
  @include extrasmall {
    margin-bottom: 20px;
    svg{
      width: 200px;
    }
  }
  @include small {
    margin-bottom: 20px;
  }
  @include medium {
    margin-bottom: 20px;
  }
  @include large {
    margin-bottom: 50px;
  }
}

