@import "../../../../styles/StyleVariables.scss";
@import "../../../../styles/StyleMixins.scss";

.showCursor {
  cursor: pointer;
}

.stakeholder-divider {
  border: 1px solid $input-border-color;
  width: 30px;
  margin: 15px auto 15px auto;
}

.dashboard-text-container {
  margin-top: 30vh;
  text-align: center;
}

.dashboard-heading {
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.dashboard-name {
  font-size: 30px;
  font-weight: bold;
  margin-top: 7px;
  margin-bottom: 17px;
  text-transform: uppercase;
  letter-spacing: 0.01rem;
}

.dashboard-admin-name {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 104px;
  text-transform: uppercase;
}

.dashboard-organisation {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: $primary-color;
}

.dashboard-button-container {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 30px;
  margin-top: 40px;
}

.view-all-container {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  margin-top: 40px;
}

.view-all-container path {
  fill: $primary-color;
}

.dashboard-button-container path {
  fill: $primary-color;
}

.dashboard-button {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: row;
}

.dashboard-button-text {
  font-weight: bold;
  font-size: 14px;
  margin-right: 3px;
  &:hover {
    text-decoration: underline;
  }
}

.dashboard-card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  @include extrasmall {
    flex-direction: column;
    margin-top: 3vh;
  }
  @include extrasmall {
    width: 90vw;
    margin-left: 5vw;
    margin-top: 3vh;
  }
  @include small {
    width: 90vw;
    margin-left: 5vw;
    margin-top: 3vh;
  }
  @include medium {
    width: 90vw;
    margin-left: 5vw;
    margin-top: 3vh;
  }
  @include large {
    width: 800px;
    margin: auto;
    margin-top: 2vh;
  }
}

.dashboard-card {
  position: relative;
  height: 300px;
  width: 30%;
  background-color: $card-color;
  border-radius: 10px;
  border: $card-border 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:hover {
    background-color: $card-hover-color;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: $background-color;
    -webkit-transition: background-color 0.5s ease;
    -moz-transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
    cursor: pointer;
    img {
      opacity: 0.3;
    }
  }
  @include extrasmall {
    width: 100%;
    height: 300px;
    margin-bottom: 20px;
  }
}

.dashboard-card-container-sponsor {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;

  @include extrasmall {
    flex-direction: column;
    margin-top: 3vh;
  }
  @include extrasmall {
    width: 90vw;
    margin-left: 5vw;
    margin-top: 3vh;
  }
  @include small {
    width: 90vw;
    margin-left: 5vw;
    margin-top: 3vh;
  }
  @include medium {
    width: 90vw;
    margin-left: 5vw;
    margin-top: 3vh;
  }
  @include large {
    width: 800px;
    margin: auto;
    margin-top: 2vh;
  }
}

.dashboard-card-sponsor {
  height: 300px;
  width: calc(50% - 10px);
  margin-bottom: 20px;
  background-color: $card-color;
  border-radius: 10px;
  border: $card-border 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:hover {
    background-color: $card-hover-color;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: $background-color;
    -webkit-transition: background-color 0.5s ease;
    -moz-transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
    cursor: pointer;
  }
  @include extrasmall {
    width: 100%;
    height: 300px;
    margin-bottom: 20px;
  }
}

.no-hover-sponsor {
  position: relative;
  height: 300px;
  width: calc(50% - 10px);
  margin-bottom: 20px;
  background-color: $card-color;
  border-radius: 10px;
  border: $card-border 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include extrasmall {
    width: 100%;
    height: 300px;
    margin-bottom: 20px;
  }
}

.card-internal-link {
  position: absolute;
  opacity: 0;
  right: 20px;
  top: 20px;
  width: 14px;
}

.dashboard-number {
  font-size: 64px;
  margin-bottom: 15px;
}

.dashboard-card-heading {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 40px;
  @include small {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.dashboard-card-title-overview {
  margin-top: 28px;
  font-size: 24px;
  font-weight: bold;
  color: $primary-color;
  text-transform: uppercase;
  margin-bottom: 14px;
  cursor: pointer;
  @include small {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.dashboard-card-heading-overview {
  margin-top: 7px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 40px;
  color: $primary-color;
  // border-top: 1.5px solid $card-border;
  padding-top: 10px;
  @include small {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.dashboard-graph-card {
  height: auto;
  width: 100%;
  background-color: $preview-card-color;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // margin-top: 35px;
  border: $card-border 1px solid;

  @include extrasmall {
    display: none;
  }
  @include medium {
    margin-top: 0px;
  }
  @include large {
    margin-top: 35px;
  }
}

.space-below {
  margin-bottom: 200px;
}

.dashboard-profile-container-one {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 50s ease-in-out;
}

.dashboard-profile-container {
  display: flex;
  flex-direction: column;
  border: solid 1px $card-border;
  background-color: $preview-card-color;
  border-radius: 10px;
  @include extrasmall {
    width: 90vw;
    margin-left: 5vw;
    margin-bottom: 5vh;
    padding: 10px;
    margin-top: 4vh;
  }
  @include small {
    width: 90vw;
    margin-left: 5vw;
    margin-bottom: 5vh;
    margin-top: 4vh;
    padding: 10px;
  }
  @include medium {
    width: 90vw;
    margin-left: 5vw;
    margin-bottom: 5vh;
    margin-top: 4vh;
    padding: 40px;
  }
  @include large {
    width: 800px;
    margin: 4vh auto 2vh auto;
    padding: 40px 40px 80px 40px;
  }
}

.dashboard-profile-text {
  text-align: left;
  overflow-wrap: break-word;
  border-bottom: 1px solid #eaedf4;
  padding-bottom: 30px;
  &:last-child{
    border-bottom: none;
    padding-bottom: 0px;
  }
}

.dashboard-profile-organisation {
  text-align: center;
  font-size: 22px;
  font-weight: bolder;
  text-transform: uppercase;
  grid-column-start: 2;
}

.dashboard-edit {
  border: solid 1px $warning-colour;
  border-radius: 4px;
  padding: 5px 16px;
  font-size: 12px;
  font-weight: bolder;
  background-color: transparent;
  color: $warning-colour;
  cursor: pointer;
  grid-column-start: 3;
  justify-self: end;
  &:hover {
    color: #fff;
    background-color: $warning-colour;
  }
}

.dashboard-profile-header {
  display: grid;
  grid-template-columns: 20% 60% 20%;
}

.plant-container {
  display: flex;
  justify-content: center;
  width: 100vw;
  margin-left: -100vw;
  margin-top: -80px;
  @include extrasmall {
    display: none;
  }
  @include small {
    display: none;
  }
  @include medium {
    display: none;
  }
  @include large {
    display: flex;
    justify-content: center;
  }
}

.admin-plant-container {
  display: flex;
  justify-content: center;
  width: 100vw;
  margin-left: -100vw;
  margin-top: -120px;
  @include extrasmall {
    display: none;
  }
  @include small {
    display: none;
  }
  @include medium {
    display: none;
  }
  @include large {
    display: flex;
    justify-content: center;
  }
}

.no-hover {
  position: relative;
  height: 300px;
  width: 30%;
  background-color: $card-color;
  border-radius: 10px;
  border: $card-border 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include extrasmall {
    width: 100%;
    height: 300px;
    margin-bottom: 20px;
  }
}

.dashboard-text-container {
  display: flex;
}

.dashboard-heading-container {
  width: 100vw;
}

.dashboard-container {
  animation: fadeIn 1s;
  @include fadein;
}
